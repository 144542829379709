/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { IcoArrowLeft, IcoClose, IcoSearch } from "assets/icons";
import colors from "colors";
import { Button, Input, Loading } from "components";
import { useFormik } from "formik";
import { Team } from "models/auth/Team";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.scss";

import ModalCancelConfirm from "components/ModalCancelConfirm";
import ToastContent from "components/ToastContent";
import { TeamEditionProps } from "models/auth/types";
import { toast } from "react-toastify";
import { TeamService } from "services/teams";
import { UserService } from "services/users";

export default function TeamCreateEdit() {
	interface User {
		id: number;
		name: string;
		role: string;
	}

	const navigate = useNavigate();

	const { id } = useParams();
	const isEdit = id;
	const [loadingTeam, setLoadingTeam] = useState(!!isEdit);
	const [initialValues, setInitialValues] = useState<TeamEditionProps>(
		Team.initialValues
	);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const userService = new UserService();
	const teamService = new TeamService();

	const [userList, setUserList] = useState<User[]>([]);
	const [loadingUsers, setLoadingUsers] = useState(true);

	const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
	const [filter, setFilter] = useState<string>("");
	const [search, setSearch] = useState<string>("");

	const [hasErrorsOnSubmit, sethasErrorsOnSubmit] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [hasMore, setHasMore] = useState(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const observer = useRef<IntersectionObserver | null>(null);

	function editTeam(values: TeamEditionProps) {
		teamService
			.editTeam(values, id!)
			.then(() => {
				setIsLoading(false);
				toast.dark(
					<ToastContent
						type="success"
						title="Time editado"
						subtitle="As informações do time foram alteradas"
						onClose={() => toast.dismiss()}
					/>,
					{
						position: "top-center",
						autoClose: 3000,
						closeOnClick: false,
						progressClassName: "confirmation-toast-success-progress",
						className: "confirmation-toast",
						bodyClassName: "confirmation-toast-body"
					}
				);
				navigate("/teams");
			})
			.catch((error) => {
				setIsLoading(false);
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>,
					{ hideProgressBar: true }
				);
			});
	}

	function createTeam(values: TeamEditionProps) {
		teamService
			.createTeam(values)
			.then(() => {
				setIsLoading(false);
				toast.dark(
					<ToastContent
						type="success"
						title="Time criado"
						subtitle="Novo time adicionado"
						onClose={() => toast.dismiss()}
					/>,
					{
						position: "top-center",
						autoClose: 3000,
						closeOnClick: false,
						progressClassName: "confirmation-toast-success-progress",
						className: "confirmation-toast",
						bodyClassName: "confirmation-toast-body"
					}
				);
				navigate("/teams");
			})
			.catch((error) => {
				setIsLoading(false);
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>,
					{ hideProgressBar: true }
				);
			});
	}

	const formik = useFormik({
		initialValues: Team.initialValues,
		validationSchema: Team.validationSchema,
		onSubmit: () => {
			setIsLoading(true);
			if (isEdit) {
				editTeam(formik.values);
			} else {
				createTeam(formik.values);
			}
		}
	});

	useEffect(() => {
		const fetchTeam = async () => {
			if (id) {
				setLoadingTeam(true);
				const team = await teamService.getTeamById(id);
				const userIds = team.users.map((user: User) => user.id);
				setSelectedUsers(userIds);
				setLoadingTeam(false);

				const newValues = {
					...formik.values,
					...team,
					userId: userIds
				};

				setInitialValues({
					...newValues
				});
				formik.setValues(() => ({
					...newValues
				}));
			}
		};
		fetchTeam();
	}, [id]);

	const loadUsers = () => {
		setLoadingUsers(true);
		setHasMore(true);
		userService
			.listPaginated({ page: currentPage, size: 2000, search, sort: "name" })
			.then((response) => {
				const newUsers = response.content;
				if (currentPage === 1) {
					setUserList(newUsers);
				} else {
					setUserList((prevUsers) => {
						const uniqueNewUsers = newUsers.filter((newUser) => {
							return !prevUsers.some((user) => user.id === newUser.id);
						});
						return [...prevUsers, ...uniqueNewUsers];
					});
				}
				if (newUsers.length <= 9) {
					setHasMore(false);
				}
			})
			.catch((error) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>,
					{
						hideProgressBar: true
					}
				);
				console.error("Error fetching users", error);
			})
			.finally(() => {
				setLoadingUsers(false);
			});
	};

	const handleObserver: IntersectionObserverCallback = (entries) => {
		const target = entries[0];
		if (target.isIntersecting && hasMore) {
			setCurrentPage((prevPage) => prevPage + 1);
		}
	};
	useEffect(() => {
		const options = {
			root: null,
			rootMargin: "0px",
			threshold: 1.0
		};

		observer.current = new IntersectionObserver(handleObserver, options);

		return () => {
			if (observer.current) {
				observer.current.disconnect();
			}
		};
	}, []);

	useEffect(() => {
		if (loadingUsers || !hasMore) return;

		const triggerElement = document.getElementById("infinite-scroll-trigger");

		if (triggerElement && observer.current) {
			observer.current.observe(triggerElement);
		}
	}, [loadingUsers, hasMore]);

	useEffect(() => {
		loadUsers();
	}, [currentPage, search]);

	const handleCheckboxChange = (userId: number) => {
		setSelectedUsers((prevSelectedUsers) => {
			let currentUserIds = [...prevSelectedUsers, userId];
			if (prevSelectedUsers.includes(userId)) {
				currentUserIds = currentUserIds.filter(
					(filteredId) => filteredId !== userId
				);
			}

			formik.setFieldValue("userId", currentUserIds);
			return currentUserIds;
		});
	};

	const closeModal = () => {
		setIsModalOpen(false);
		navigate("/teams");
	};

	const confirmModalAction = () => {
		formik.handleSubmit();
		setIsModalOpen(false);
	};

	const sortedUserList = [...userList].sort((a, b) => {
		const aIsSelected = selectedUsers.includes(a.id);
		const bIsSelected = selectedUsers.includes(b.id);

		if (aIsSelected === bIsSelected) {
			return 0;
		}

		return aIsSelected ? -1 : 1;
	});

	const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilter(event.target.value);
	};
	const handleSearchUser = () => {
		setSearch(filter);
		setCurrentPage(1);
	};

	const validateThenHandleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			sethasErrorsOnSubmit(true);
		} else {
			formik.handleSubmit();
		}
	};

	const hasChanges = () =>
		JSON.stringify(formik.values) !== JSON.stringify(initialValues);

	const handleRemoveFilters = () => {
		setSearch("");
		setFilter("");
		setUserList([]);
		setCurrentPage(1);
	};

	return (
		<form className="edition-page">
			<div className="edition-header">
				<Button
					kind="icon"
					styled="ghost"
					onClick={() =>
						hasChanges() ? setIsModalOpen(true) : navigate("/teams")
					}
				>
					<IcoArrowLeft color={colors.neutral["low-pure-500"]} />
				</Button>
				<span className="edition-title">
					{isEdit ? "Editar time" : "Criar time"}
				</span>
			</div>
			{loadingTeam ? (
				<Loading />
			) : (
				<div className="edition-content">
					<div className="edition-field-title">Time</div>
					<Input
						label="Nome do time"
						name="name"
						placeholder="Digite"
						value={formik.values.name}
						onChange={(e) => {
							formik.handleChange(e);
						}}
						error={hasErrorsOnSubmit ? formik.errors.name : undefined}
						className={`team-name-input ${
							hasErrorsOnSubmit && formik.errors.name ? "input-error" : ""
						}`}
					/>
					<div className="edition-field-title">Adicionar usuários</div>
					{loadingUsers && sortedUserList.length === 0 ? (
						<Loading />
					) : (
						<>
							<div className="user-search-filter">
								<input
									placeholder="Digite"
									value={filter}
									onChange={(e) => {
										if (e.target.value.length <= 0) {
											setSearch("");
										}
										handleFilterChange(e);
									}}
									className="team-edition-input"
									onKeyUp={(e) => {
										if (e.key === "Enter") handleSearchUser();
									}}
								/>
								<button
									type="button"
									className="search-icon-wrapper"
									onClick={
										search.length > 0 ? handleRemoveFilters : handleSearchUser
									}
								>
									{search.length > 0 ? (
										<IcoClose />
									) : (
										<IcoSearch
											color={colors.neutral["low-pure-500"]}
											size="1rem"
										/>
									)}
								</button>
							</div>

							<div className="user-select-wrapper">
								{sortedUserList.map((user) => (
									<div
										key={user.id}
										className="user-list-item"
										onClick={() => handleCheckboxChange(user.id)}
									>
										<input
											id={user.id.toString()}
											type="checkbox"
											value={user.id}
											checked={selectedUsers.includes(user.id)}
										/>
										<label
											htmlFor={user.id.toString()}
											className="user"
											onClick={(event) => event.stopPropagation()}
										>
											<span className="user-name">{user.name}</span>
											<span className="user-role">{user.role}</span>
										</label>
									</div>
								))}
								{hasMore && (
									<div
										id="infinite-scroll-trigger"
										className="infinite-scroll"
									/>
								)}
							</div>
							<div className="buttons">
								<Button
									cssClass="cancel-button"
									kind="default"
									styled="secondary"
									type="button"
									size="medium"
									onClick={() =>
										hasChanges() ? setIsModalOpen(true) : navigate("/teams")
									}
								>
									Cancelar
								</Button>
								<Button
									cssClass="submit-button"
									kind="default"
									styled="primary"
									type="button"
									size="medium"
									onClick={(e: any) => validateThenHandleSubmit(e)}
									disabled={!formik.dirty}
									isLoading={isLoading}
								>
									Salvar time
								</Button>
							</div>
						</>
					)}
				</div>
			)}
			<ModalCancelConfirm
				modalTitle="Deseja sair sem salvar?"
				modalInfo="Ao sair sem salvar, suas ações serão perdidas"
				isOpen={isModalOpen}
				onClose={closeModal}
				onConfirm={confirmModalAction}
				closeLabel="Sair sem salvar"
				confirmLabel="Salvar"
				toastSuccessTitle={isEdit ? "Time editado" : "Time criado"}
				toastSuccessMessage={
					isEdit
						? "As informações do time foram alteradas"
						: "Novo time adicionado"
				}
				kind="warning"
			/>
		</form>
	);
}
