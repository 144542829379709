import { IconProps } from "./types";

export default function IcoFilter({
	color = "#5E1CCA",
	size = "20"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.3904 3.33325H4.58312C3.46333 3.33325 2.89041 4.68742 3.6977 5.46867L7.91645 9.68742V14.1666C7.91645 14.5833 8.09874 14.9478 8.41124 15.2083L10.0779 16.4583C10.8852 17.0051 12.0831 16.4843 12.0831 15.4426V9.68742L16.2758 5.46867C17.0831 4.68742 16.5102 3.33325 15.3904 3.33325ZM10.8331 9.16658V15.4166L9.16645 14.1666V9.16658L4.58312 4.58325H15.4165L10.8331 9.16658Z"
				fill={color}
			/>
		</svg>
	);
}
