import {
	ColumnDef,
	OnChangeFn,
	RowModel,
	RowSelectionState,
	SortingState
} from "@tanstack/react-table";
import {
	Button,
	ColoredTag,
	SettleModal as CovenantSettleModal,
	Pagination,
	Table
} from "components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { IcoCheckRounded, IcoExport, IcoFile, IcoPencil } from "assets/icons";
import { Covenant } from "models/covenant/Covenant";
import moment from "moment";

import ToastContent from "components/ToastContent";
import { FileHelper } from "helpers";
import { CovenantStatus } from "models/covenant/enums";
import { toast } from "react-toastify";
import { CovenantService } from "services/covenants";
import "./styles.scss";

type CovenantTableProps = {
	content: Covenant[];
	pageSize: number;
	currentPage: number;
	totalPages: number;
	onPageChange: (selectedPage: number, pageSize: number) => void;
	sorting?: SortingState;
	setSorting?: OnChangeFn<SortingState>;
};

export default function CovenantsTable({
	content,
	pageSize,
	currentPage,
	totalPages,
	onPageChange,
	sorting,
	setSorting
}: CovenantTableProps) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [covenantForSettle, setCovenantForSettle] = useState<Covenant>();
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
	const [selectionRowModel, setSelectedRowModel] = useState<RowModel<any>>();
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();
	const covenantService = new CovenantService();

	const getStatusType = (
		statusValue: string
	): "default" | "success" | "alert" | "error" => {
		if (statusValue === CovenantStatus.Ativo.name) {
			return "success";
		}
		return "default";
	};

	const columns: ColumnDef<Covenant>[] = [
		{
			id: "contractingCompanies",
			accessorKey: "contractingCompaniesNames",
			header: () => <span>Contratante</span>,
			enableSorting: false,
			cell: (row) => {
				return (
					<div className="row-name">
						<p>{row.getValue() as string}</p>
					</div>
				);
			},
			meta: {
				columnClass: "name-column"
			}
		},
		{
			id: "status",
			accessorKey: "status.name",
			header: () => <span>Status</span>,
			cell: (row) => {
				return (
					<ColoredTag
						text={row.getValue() as string}
						type={getStatusType(row.getValue() as string)}
					/>
				);
			},
			meta: {
				columnClass: "status-column"
			}
		},
		{
			id: "covenantNumber",
			accessorKey: "covenantNumber",
			header: () => <span>Número</span>,
			meta: {
				columnClass: "covenantNumber-column"
			}
		},
		{
			id: "totalValue",
			accessorKey: "totalValue",
			header: () => <span>Valor</span>,
			cell: ({ row }) => {
				const formatter = new Intl.NumberFormat("pt-BR", {
					style: "currency",
					currency: row.original.currency.name
				});
				return formatter.format(row.original.totalValue);
			},
			meta: {
				columnClass: "totalValue-column"
			}
		},
		{
			id: "signatureDate",
			accessorKey: "signatureDate",
			header: () => <span>Assinatura</span>,
			cell: (row) => {
				return moment(row.getValue() as Date).format("DD/MM/YYYY");
			},
			meta: {
				columnClass: "signatureDate-column"
			}
		},
		{
			id: "expirationDate",
			accessorKey: "expirationDate",
			cell: (row) => {
				return moment(row.getValue() as Date).format("DD/MM/YYYY");
			},
			header: () => <span>Vencimento</span>,
			meta: {
				columnClass: "expirationDate-column"
			}
		},
		{
			id: "action",
			header: "Ações",
			enableSorting: false,
			cell: ({ row }) => (
				<span className="button-actions">
					<Tooltip anchorSelect=".detail-button" place="top" className="z-50">
						Detalhes
					</Tooltip>
					<Button
						kind="icon"
						styled="ghost"
						size="large"
						onClick={() => {
							// eslint-disable-next-line react/prop-types
							navigate(`/covenants/details/${row.original.covenantId}`);
						}}
						cssClass="detail-button"
					>
						<IcoFile size="1.75rem" />
					</Button>
					<Tooltip anchorSelect=".edit-button" place="top" className="z-50">
						Editar contrato
					</Tooltip>
					<Button
						kind="icon"
						styled="ghost"
						size="large"
						onClick={() =>
							navigate(`/covenants/edit/${row.original.covenantId}`)
						}
						cssClass="edit-button"
						disabled={row.original.alreadySettled}
					>
						<IcoPencil size="1.75rem" />
					</Button>
					<Tooltip anchorSelect=".settle-button" place="top" className="z-50">
						Liquidar
					</Tooltip>
					<Button
						kind="icon"
						styled="ghost"
						size="large"
						onClick={() => {
							setCovenantForSettle(row.original);
							setIsModalOpen(true);
						}}
						cssClass="settle-button"
						disabled={row.original.alreadySettled}
					>
						<IcoCheckRounded size="16px" />
					</Button>
				</span>
			),
			meta: {
				columnClass: "action-column"
			}
		}
	];

	const closeModal = () => {
		setCovenantForSettle(undefined);
		setIsModalOpen(false);
	};

	const confirmModalAction = () => {
		setCovenantForSettle(undefined);
		setIsModalOpen(false);
		onPageChange(currentPage, pageSize);
	};

	const exportCovenants = () => {
		setLoading(true);
		const numberRows = Object.keys(rowSelection).length;
		const labelSelected = numberRows > 1 ? "Foram exportados" : "Foi exportado";
		const labelCovenants = numberRows > 1 ? "contratos" : "contrato";

		const selectedRows = selectionRowModel?.rows.map(
			(row) => row.original.covenantId as string
		);
		if (selectedRows) {
			covenantService
				.exportCovenants(selectedRows)
				.then((response) => {
					setLoading(false);

					toast.dark(
						<ToastContent
							type="success"
							title="Contrato exportado"
							subtitle={`${labelSelected} ${numberRows} ${labelCovenants}`}
							onClose={() => toast.dismiss()}
						/>,
						{
							position: "top-center",
							autoClose: 3000,
							closeOnClick: false,
							progressClassName: "confirmation-toast-success-progress",
							className: "confirmation-toast",
							bodyClassName: "confirmation-toast-body"
						}
					);
					FileHelper.downloadExcelFile(response, "covenants.xlsx");
				})
				.catch((error) => {
					setLoading(false);
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={error}
							onClose={() => toast.dismiss()}
						/>
					);
				});
		}
	};

	const selectionActions = (
		<div className="selectionActionsCovenants">
			<Button
				styled="secondary"
				onClick={exportCovenants}
				cssClass={loading ? "loading-button-covenants" : ""}
			>
				<IcoExport size="20" />
				<div>Exportar</div>
			</Button>
		</div>
	);

	const selectionLabel = () => {
		const numberRows = Object.keys(rowSelection).length;
		const labelName = numberRows > 1 ? "contratos" : "contrato";
		const labelSelected = numberRows > 1 ? "selecionados" : "selecionado";
		return (
			<>
				<b>{`${numberRows} ${labelName} `}</b>
				{`${labelSelected} no total`}
			</>
		);
	};

	return (
		<div className="covenant-table">
			<Table
				columns={columns}
				data={content}
				sorting={sorting}
				setSorting={setSorting}
				rowSelection={rowSelection}
				setRowSelection={setRowSelection}
				selectionActions={selectionActions}
				selectionLabel={selectionLabel}
				setSelectedRowModel={setSelectedRowModel}
			/>
			<Pagination
				totalPages={totalPages}
				currentPage={currentPage}
				onPageChange={onPageChange}
				pageSize={pageSize}
			/>
			<CovenantSettleModal
				itemForSettleId={covenantForSettle?.covenantId}
				itemForService={CovenantService}
				confirMessage={`O contrato foi liquidado${
					covenantForSettle?.hasExpired ? "" : " antecipadamente"
				}`}
				modalTitle="Liquidar contrato"
				modalInfo={`Ao confirmar, o contrato será liquidado${
					covenantForSettle?.hasExpired ? "" : " antecipadamente"
				}`}
				isOpen={isModalOpen}
				onClose={closeModal}
				onConfirm={confirmModalAction}
				kind="warning"
			/>
		</div>
	);
}
