import { IconProps } from "./types";

export default function IcoClose({
	color = "#1D1D1D",
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18 6L6 18"
				stroke={color}
				strokeWidth="1"
				strokeLinecap="square"
			/>
			<path
				d="M6 6L18 18"
				stroke={color}
				strokeWidth="1"
				strokeLinecap="square"
			/>
		</svg>
	);
}
