import { IcoArrowRight } from "assets/icons";
import colors from "colors";
import { Button, Drawer } from "components";
import { User } from "models/auth/User";

import { useNavigate } from "react-router-dom";
import "./styles.scss";

type UserDetailsDrawerProps = {
	isOpen: boolean;
	onClose: () => void;
	user: User | null;
};

export default function UserDetailsDrawer({
	isOpen,
	onClose,
	user
}: UserDetailsDrawerProps) {
	const navigate = useNavigate();

	return (
		<Drawer isOpen={isOpen} onClose={onClose} title="Detalhes">
			{user && (
				<div className="user-details-drawer">
					<p className="user-info-title">Informações do colaborador</p>
					<div className="user-info-wrapper">
						<div className="user-info-row">
							<span className="user-info-label">Nome</span>
							<span className="user-info-value">{user.name}</span>
						</div>
						<div className="user-info-row">
							<span className="user-info-label">Email</span>
							<span className="user-info-value email-overflow" id="test-email">
								{user.email}
							</span>
						</div>
						<div className="user-info-row">
							<span className="user-info-label">Status</span>
							<span className="user-info-value">
								{user.enabled ? "Ativo" : "Inativo"} desde{" "}
								{new Date(user.enabledOn).toLocaleDateString()}
							</span>
						</div>
						<div className="user-info-row">
							<span className="user-info-label">Perfil</span>
							<span className="user-info-value">
								{user.props.rolesString.join(", ")}
							</span>
						</div>
						<div className="user-info-row">
							<span className="user-info-label">Última atividade</span>
							<span className="user-info-value">{user.lastLoginDate}</span>
						</div>
					</div>
					<p className="associated-team-title">Times associados</p>
					<div className="associated-team-wrapper">
						{user.teams?.map((team) => (
							<div
								className="associated-team-row"
								key={`associated-team-${team.id}`}
							>
								<span className="associated-team">{team.name}</span>
								<span>
									<Button
										cssClass="right-arrow"
										kind="icon"
										styled="ghost"
										type="button"
										onClick={() =>
											navigate(
												`/teams?textFilter=${team.name}&details=${team.id}`
											)
										}
									>
										<IcoArrowRight color={colors.neutral["low-pure-500"]} />
									</Button>
								</span>
							</div>
						))}
					</div>
				</div>
			)}
		</Drawer>
	);
}
