export const CovenantType = {
	ContratoAlienacaoFiduciaria: {
		code: 1,
		name: "Contrato de Alienação Fiduciária de Ações"
	},
	ContratoCessaoFiduciaria: { code: 2, name: "Contrato de Cessão Fiduciária" },
	ContratoFinanciamento: { code: 3, name: "Contrato de Financiamento" },
	EscrituraDebenture: { code: 4, name: "Escritura de Debêntures" },
	LoanAgreement: { code: 5, name: "Loan Agreement" },
	NotaPromissoriaComercial: { code: 6, name: "Nota Promissória Comercial" },
	PenhorAcoes: { code: 7, name: "Contrato de Penhor de Ações" },
	Derivativos: { code: 8, name: "Contrato Global de Derivativos" }
};

export const CovenantTypeOptions = Object.keys(CovenantType).map((key) => ({
	label: CovenantType[key as keyof typeof CovenantType].name,
	value: CovenantType[key as keyof typeof CovenantType].code
}));

export const Currency = {
	BRL: { code: 1, name: "BRL" }
	// USD: { code: 2, name: "USD" },
	// EUR: { code: 3, name: "EUR" }
};

export const CurrencyOptions = Object.keys(Currency).map((key) => ({
	label: Currency[key as keyof typeof Currency].name,
	value: Currency[key as keyof typeof Currency].code
}));

export const CovenantStatus = {
	Ativo: { code: 1, name: "Ativo" },
	LiquidacaoAntecipada: { code: 2, name: "Liquidação antecipada" },
	Liquidado: { code: 3, name: "Liquidado" }
};

export const CovenantStatusOptions = Object.keys(CovenantStatus).map((key) => ({
	label: CovenantStatus[key as keyof typeof CovenantStatus].name,
	value: CovenantStatus[key as keyof typeof CovenantStatus].code
}));

export const ContractingType = {
	Beneficiaria: { code: 1, name: "Beneficiária" },
	Cedente: { code: 2, name: "Cedente" },
	Emissora: { code: 3, name: "Emissora" },
	IntervenienteAnuente: { code: 4, name: "Interveniente Anuente" },
	Tomadora: { code: 5, name: "Tomadora" }
};

export const ContractingTypeOptions = Object.keys(ContractingType).map(
	(key) => ({
		label: ContractingType[key as keyof typeof ContractingType].name,
		value: ContractingType[key as keyof typeof ContractingType].code
	})
);

export const CreditorType = {
	AgenteFiduciario: { code: 1, name: "Agente Fiduciário" },
	Banco: { code: 2, name: "Banco" },
	Credor: { code: 3, name: "Credor" },
	Parte: { code: 4, name: "Parte" }
};

export const CreditorTypeOptions = Object.keys(CreditorType).map((key) => ({
	label: CreditorType[key as keyof typeof CreditorType].name,
	value: CreditorType[key as keyof typeof CreditorType].code
}));

export const Creditor = {
	BNDES: {
		code: 1,
		name: "Banco Nacional de Desenvolvimento Econômico e Social - BNDES"
	},
	Bancos: {
		code: 2,
		name: "BANCO SANTANDER (BRASIL) S.A. e BANCO SANTANDER (BRASIL) S.A., LUXEMBOURG BRANCH ('Bancos')"
	},
	Pentagono: {
		code: 3,
		name: "Pentágono S.A - Distribuidora de Títulos e Valores Mobiliários"
	},
	SimplificPavarini: {
		code: 4,
		name: "Simplific Pavarini Distribuidora de Títulos e Valores Mobiliários LTDA."
	},
	Vortx: {
		code: 5,
		name: "Vórtx Distribuidora de Títulos e Valores Mobiliários Ltda."
	}
};

export const CreditorOptions = Object.keys(Creditor).map((key) => ({
	label: Creditor[key as keyof typeof Creditor].name,
	value: Creditor[key as keyof typeof Creditor].code
}));

export const ClauseStatus = {
	Ativa: { code: 1, name: "Ativa" },
	Encerrada: { code: 2, name: "Encerrada" }
};

export const ClauseStatusOptions = Object.keys(ClauseStatus).map((key) => ({
	label: ClauseStatus[key as keyof typeof ClauseStatus].name,
	value: ClauseStatus[key as keyof typeof ClauseStatus].code
}));

export const Frequency = {
	monthly: { code: 1, name: "Mensal" },
	bimonthly: { code: 2, name: "Bimestral" },
	quarterly: { code: 3, name: "Trimestral" },
	semiannual: { code: 4, name: "Semestral" },
	yearly: { code: 5, name: "Anual" }
};

export const FrequencyOptions = Object.keys(Frequency).map((key) => ({
	label: Frequency[key as keyof typeof Frequency].name,
	value: Frequency[key as keyof typeof Frequency].code
}));

export const MonitoringLevel = {
	level1: { code: 1, name: "E-mail" },
	level2: { code: 2, name: "Teams" }
};

export const MonitoringLevelOptions = Object.keys(MonitoringLevel).map(
	(key) => ({
		label: MonitoringLevel[key as keyof typeof MonitoringLevel].name,
		value: MonitoringLevel[key as keyof typeof MonitoringLevel].code
	})
);

export const TaskStatus = {
	pending: { code: 1, name: "Pendente" },
	unfulfilled: { code: 2, name: "Não iniciado" },
	fulfilled: { code: 3, name: "Atendida" },
	siacorp: { code: 4, name: "Legado" }
};

export const TaskStatusOptions = Object.keys(TaskStatus)
	.map((key) => ({
		label: TaskStatus[key as keyof typeof TaskStatus].name,
		value: TaskStatus[key as keyof typeof TaskStatus].code
	}))
	.filter((option) => option.value !== TaskStatus.pending.code);
